import React from "react";
import { v4 } from "uuid";

import { CalendarIcon, Icon, PersonIcon } from "@primer/octicons-react";
import {
    ContactDynamicDataType,
    DynamicDataConfig,
    DynamicDataConfigs,
    DynamicDataSetting,
    DynamicDataType,
    NonWriteWithAiDynamicDataType,
} from "../models/dynamicData";
import { LegoInlineStyle } from "../models/legos";

export const DYNAMIC_DATA_SPAN_CLASS_NAME = "dynamic-data-span";
export const LINK_SPAN_CLASS_NAME = "link-span";
export const STYLED_TEXT_SPAN_CLASS_NAME = "styled-text-span";
export const NEW_LINE_CLASS_NAME = "new-line";

export const DYNAMIC_DATA_TYPE_ATTRIBUTE_NAME = "data-dynamic-data-type";
export const LINK_HREF_ATTRIBUTE_NAME = "data-href";

export const DYNAMIC_DATA_REGEX = /\$([a-z\-]+):([a-zA-Z0-9\-]+)\$/g;

export const getCursor = (selection: Selection | null) => {
    let range = null,
        container = null,
        curNode = null,
        prevNode = null,
        startOffset = null,
        nextNode = null,
        twoPrevNode = null;

    if (selection && selection.getRangeAt && selection.rangeCount > 0) {
        range = selection.getRangeAt(0);

        container = range.startContainer as HTMLElement;
        startOffset = range.startOffset;
        const endOffset = range.endOffset;
        if (container.childNodes.length > 0) {
            curNode = container.childNodes[startOffset];

            if (startOffset > 0) {
                prevNode = container.childNodes[startOffset - 1];
            }

            if (startOffset > 1) {
                twoPrevNode = container.childNodes[startOffset - 2];
            }

            if (endOffset < container.childNodes.length - 1) {
                nextNode = container.childNodes[endOffset + 1];
            }
        }
    }

    return { range, container, curNode, prevNode, startOffset, nextNode, twoPrevNode };
};

export const insertNodeAtCursor = (node: Node, contentEditableElement: HTMLDivElement, customRange?: Range) => {
    const selection = window.getSelection();
    const cursor = getCursor(selection);
    const { curNode } = cursor;

    let range = customRange ?? cursor.range;

    if (!selection || !range) {
        return;
    }

    if (curNode && curNode.nodeName === "BR") {
        curNode.remove();
    }

    range.deleteContents();

    if (contentEditableElement.innerHTML === `<div class="${NEW_LINE_CLASS_NAME}"><br></div>`) {
        contentEditableElement.childNodes[0].removeChild(contentEditableElement.childNodes[0].childNodes[0]);
        contentEditableElement.childNodes[0].appendChild(node);
    } else {
        range.insertNode(node);

        if (node.parentNode === contentEditableElement) {
            contentEditableElement.removeChild(node);
            const container = document.createElement("div");
            container.appendChild(node);
            range.insertNode(container);
        }
    }

    range = range.cloneRange();
    range.setStartAfter(node);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
};

export const cleanTextForBrowser = (text: string) => {
    let newText = text.replace(/&/g, "&amp;");
    newText = newText.replace(/</g, "&lt;");
    newText = newText.replace(/>/g, "&gt;");
    return newText;
};

export const cleanTextForBackend = (text: string, shouldReplaceDollarSign = true) => {
    let newText = text;
    if (shouldReplaceDollarSign) {
        newText = newText.replace(/(\$)/g, "\\$1");
    }
    newText = newText.replace(/&amp;/g, "&");
    newText = newText.replace(/&lt;/g, "<");
    newText = newText.replace(/&gt;/g, ">");
    return newText;
};

export const insertDynamicDataNodeAtCursor = ({
    dynamicDataType,
    contentEditableElement,
    makeOnClick,
}: {
    dynamicDataType: DynamicDataType;
    contentEditableElement: HTMLDivElement;
    makeOnClick: (element: HTMLSpanElement) => () => void;
}) => {
    const id = v4();

    const dynamicVariableElement = document.createElement("span");
    dynamicVariableElement.className = DYNAMIC_DATA_SPAN_CLASS_NAME;
    dynamicVariableElement.textContent = getDynamicDataLabel(dynamicDataType);
    dynamicVariableElement.contentEditable = "false";
    dynamicVariableElement.onclick = makeOnClick(dynamicVariableElement);
    dynamicVariableElement.id = id;

    dynamicVariableElement.setAttribute(DYNAMIC_DATA_TYPE_ATTRIBUTE_NAME, `${dynamicDataType}`);
    insertNodeAtCursor(dynamicVariableElement, contentEditableElement);
};

export const insertWriteWithAiAtCursor = ({
    config,
    customRange,
    contentEditableElement,
    makeOnClick,
    setDynamicDataConfigs,
}: {
    config: DynamicDataConfig;
    customRange: Range;
    contentEditableElement: HTMLDivElement;
    makeOnClick: (element: HTMLSpanElement) => () => void;
    setDynamicDataConfigs: React.Dispatch<React.SetStateAction<DynamicDataConfigs>>;
}) => {
    const writeWithAiElement = document.createElement("span");
    writeWithAiElement.className = DYNAMIC_DATA_SPAN_CLASS_NAME;
    writeWithAiElement.textContent = "Write with AI";
    writeWithAiElement.contentEditable = "false";
    writeWithAiElement.onclick = makeOnClick(writeWithAiElement);
    writeWithAiElement.id = config.uuid;

    writeWithAiElement.setAttribute(DYNAMIC_DATA_TYPE_ATTRIBUTE_NAME, `${config.dynamic_data_type}`);
    insertNodeAtCursor(writeWithAiElement, contentEditableElement, customRange);

    // TODO! add style tag if in style tag
    // make sure onDelete removes the surrounding style tag if one
    setDynamicDataConfigs((prev) => ({ ...prev, [config.uuid]: config }));
};

export const insertLinkNodeAtCursor = ({
    text,
    href,
    contentEditableElement,
    customRange,
    makeOnClick,
}: {
    text: string;
    href: string;
    customRange: Range;
    contentEditableElement: HTMLDivElement;
    makeOnClick: (element: HTMLSpanElement) => () => void;
}) => {
    const linkElement = document.createElement("span");
    linkElement.className = LINK_SPAN_CLASS_NAME;
    linkElement.textContent = text;
    linkElement.contentEditable = "false";
    linkElement.onclick = makeOnClick(linkElement);

    linkElement.setAttribute(LINK_HREF_ATTRIBUTE_NAME, href);

    if (isSelectionSingleTextNodeWithStyleSpanParent(customRange) && customRange.startContainer.parentElement) {
        createSiblingStyleSpansWhenSelectionInsideOneStyleSpan({
            range: customRange,
            styleDeclaration: { textDecoration: "underline" },
            shouldCloneFinalRange: false,
            customChildElement: linkElement,
        });
    } else {
        const styleSpan = document.createElement("span");
        styleSpan.className = STYLED_TEXT_SPAN_CLASS_NAME;
        styleSpan.style.textDecoration = "underline";
        styleSpan.contentEditable = "false";
        styleSpan.appendChild(linkElement);
        insertNodeAtCursor(styleSpan, contentEditableElement, customRange);
    }
};

export const replaceNewlineAndAddTopLevelDivContainers = (html: string) => {
    let newHtml = `<div class="${NEW_LINE_CLASS_NAME}">`;
    let lineHasContent = false;
    for (const char of html) {
        if (char === "\n") {
            newHtml += `${lineHasContent ? "" : "<br />"}</div><div class="${NEW_LINE_CLASS_NAME}">`;
            lineHasContent = false;
        } else {
            newHtml += char;
            lineHasContent = true;
        }
    }
    newHtml += "</div>";

    newHtml = newHtml.replaceAll(`<div class="${NEW_LINE_CLASS_NAME}"></div>`, "");

    if (newHtml === "") {
        newHtml = `<div class="${NEW_LINE_CLASS_NAME}"><br></div>`;
    }

    return newHtml;
};

export const substitutePreviewTextForDynamicDataTypes = (text: string) => {
    let newText = text;
    for (const match of text.matchAll(DYNAMIC_DATA_REGEX) ?? []) {
        const dynamicDataType = match[1] as DynamicDataType;

        if (validDynamicDataVariables.has(dynamicDataType)) {
            newText = newText.replace(match[0], `[${getDynamicDataLabel(dynamicDataType)}]`);
        }
    }

    newText = newText.replace(/\\\$/g, "$");

    return newText;
};

export const removeUnusedDynamicDataConfigs = (template: string, dynamicDataConfigs: DynamicDataConfigs) => {
    const dynamicDataConfigsCopy = { ...dynamicDataConfigs };
    for (const key in dynamicDataConfigsCopy) {
        if (!template.includes(key)) {
            delete dynamicDataConfigsCopy[key];
        }
    }

    return dynamicDataConfigsCopy;
};

export const parseInitialHtmlForDynamicData = ({
    text,
    shouldReplaceDollarSign = true,
    allowLinks = true,
}: {
    text: string;
    shouldReplaceDollarSign?: boolean;
    allowLinks?: boolean;
}) => {
    const { modifiedString, spanUuidDict } = temporarilyRemoveSpanTags(text);

    let newHtml = modifiedString;

    newHtml = cleanTextForBrowser(newHtml);

    for (const match of text.matchAll(DYNAMIC_DATA_REGEX) ?? []) {
        const dynamicDataType = match[1] as DynamicDataType;
        const id = match[2];

        if (validDynamicDataVariables.has(dynamicDataType)) {
            newHtml = newHtml.replace(
                match[0],
                `<span class="${DYNAMIC_DATA_SPAN_CLASS_NAME}" contenteditable="false" id="${id}" ${DYNAMIC_DATA_TYPE_ATTRIBUTE_NAME}="${dynamicDataType}">${getDynamicDataLabel(dynamicDataType)}</span>`,
            );
        }
    }

    if (allowLinks) {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let match;
        while ((match = linkRegex.exec(newHtml)) !== null) {
            const linkText = match[1];
            const href = match[2];

            const linkSpan = `<span class="${LINK_SPAN_CLASS_NAME}" contenteditable="false" ${LINK_HREF_ATTRIBUTE_NAME}="${cleanTextForBrowser(href)}">${cleanTextForBrowser(linkText)}</span>`;

            newHtml = `${newHtml.slice(0, match.index)}${linkSpan}${newHtml.slice(match.index + match[0].length)}`;
        }
    }

    if (shouldReplaceDollarSign) {
        newHtml = newHtml.replace(/\\\$/g, "$");
    }

    newHtml = replaceNewlineAndAddTopLevelDivContainers(newHtml);

    newHtml = addBackSpanTags(newHtml, spanUuidDict);

    newHtml = newHtml.replaceAll("font-weight: 400", "font-weight: 300");
    newHtml = newHtml.replaceAll("font-weight: 700", "font-weight: 500");

    return newHtml;
};

export const ensureTopLevelWrapper = (contentEditableElement: HTMLDivElement) => {
    let newHtml =
        contentEditableElement.innerHTML === "<br>" ||
        contentEditableElement.innerHTML === "" ||
        contentEditableElement.innerHTML === undefined ||
        contentEditableElement.innerHTML === "<div></div>" ||
        contentEditableElement.innerHTML === `<div class="${NEW_LINE_CLASS_NAME}"></div>`
            ? `<div class="${NEW_LINE_CLASS_NAME}"><br></div>`
            : contentEditableElement.innerHTML;

    newHtml = newHtml.replaceAll(`<div class="${NEW_LINE_CLASS_NAME}"></div>`, "");
    return newHtml.replaceAll("<div></div>", "");
};

export const parsePreviewTextWithDynamicData = ({ text, settings }: { text: string; settings: DynamicDataSetting }) => {
    let newText = text;

    const variablesFound: DynamicDataType[] = [];
    for (const match of text.matchAll(DYNAMIC_DATA_REGEX) ?? []) {
        const dynamicDataType = match[1] as DynamicDataType;

        if (validDynamicDataVariables.has(dynamicDataType)) {
            newText = newText.replace(match[0], getDynamicDataPreviewText(dynamicDataType, settings));
            variablesFound.push(dynamicDataType);
        }
    }

    newText = newText.replace(/\\\$/g, "$");

    return { newText, variablesFound };
};

export const parsePreviewTextWithDynamicData2 = ({
    text,
    settings,
}: {
    text: string;
    settings: DynamicDataSetting;
}) => {
    let newText = text;

    for (const match of text.matchAll(DYNAMIC_DATA_REGEX) ?? []) {
        const dynamicDataType = match[1] as DynamicDataType;

        if (validDynamicDataVariables.has(dynamicDataType)) {
            newText = newText.replace(match[0], getDynamicDataPreviewText(dynamicDataType, settings));
        }
    }

    newText = newText.replace(/\\\$/g, "$");

    return newText;
};

export const createReactCopyOfNodes = (nodes: ChildNode[]) => {
    const reactNodeList: React.ReactNode[] = [];

    const createReactCopyOfNodesRecursive = (nodes: ChildNode[]) => {
        nodes.forEach((node) => {
            if (node.hasChildNodes()) {
                createReactCopyOfNodesRecursive(Array.from(node.childNodes));
            } else if (node.nodeName === "BR") {
                const breakNode = (
                    <React.Fragment key={v4()}>
                        <br />
                    </React.Fragment>
                );
                reactNodeList.push(breakNode);
            } else if (node.nodeType === Node.TEXT_NODE && node.textContent) {
                const textNode = <React.Fragment key={v4()}>{node.textContent}</React.Fragment>;
                reactNodeList.push(textNode);
            }
        });
    };

    createReactCopyOfNodesRecursive(nodes);
    return reactNodeList;
};

const parseChildNodesToBackend = ({
    childNodes,
    shouldReplaceDollarSign = true,
    allowLinks = true,
}: {
    childNodes: NodeListOf<ChildNode>;
    shouldReplaceDollarSign?: boolean;
    allowLinks?: boolean;
}) => {
    let html = "";
    let isFirstLine = true;
    for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];

        if (childNode.nodeName === "DIV" && !isFirstLine) {
            html += "\n";
        }

        isFirstLine = false;

        if (childNode.nodeType === Node.TEXT_NODE && childNode.textContent) {
            html += cleanTextForBackend(childNode.textContent, shouldReplaceDollarSign);
        } else if (
            childNode.nodeName === "SPAN" &&
            (childNode as HTMLSpanElement).className === DYNAMIC_DATA_SPAN_CLASS_NAME
        ) {
            html += `$${(childNode as HTMLSpanElement).getAttribute(DYNAMIC_DATA_TYPE_ATTRIBUTE_NAME)}:${(childNode as HTMLSpanElement).id}$`;
        } else if (
            allowLinks &&
            childNode.nodeName === "SPAN" &&
            (childNode as HTMLSpanElement).className === LINK_SPAN_CLASS_NAME
        ) {
            const backendLinkText = cleanTextForBackend((childNode as HTMLSpanElement).textContent ?? "", false);
            const backendHref = cleanTextForBackend(
                (childNode as HTMLSpanElement).getAttribute(LINK_HREF_ATTRIBUTE_NAME) ?? "",
                false,
            );
            html += `[${backendLinkText}](${backendHref})`;
        } else if (
            allowLinks &&
            childNode.nodeName === "SPAN" &&
            (childNode as HTMLSpanElement).className === STYLED_TEXT_SPAN_CLASS_NAME
        ) {
            const contentEditableAttr = !!(childNode as HTMLSpanElement).contentEditable
                ? ` content-editable="${(childNode as HTMLSpanElement).contentEditable}"`
                : "";
            html += `<span style="${(childNode as HTMLSpanElement).style.cssText}"${contentEditableAttr} class="${STYLED_TEXT_SPAN_CLASS_NAME}">${parseChildNodesToBackend({ childNodes: childNode.childNodes, allowLinks, shouldReplaceDollarSign })}</span>`;
        } else {
            html += parseChildNodesToBackend({
                childNodes: childNode.childNodes,
                allowLinks,
                shouldReplaceDollarSign,
            });
        }
    }

    html = html.replaceAll("font-weight: 300", "font-weight: 400");
    html = html.replaceAll("font-weight: 500", "font-weight: 700");
    return html;
};

export const parseDynamicDataHtmlToBackend = ({
    childNodes,
    innerHtml,
    shouldReplaceDollarSign = true,
    allowLinks = true,
}: {
    childNodes: NodeListOf<ChildNode>;
    innerHtml: string;
    shouldReplaceDollarSign?: boolean;
    allowLinks?: boolean;
}) => {
    if (innerHtml === `<div class="${NEW_LINE_CLASS_NAME}"><br></div>` || innerHtml === "<br>") {
        return "";
    }

    const result = parseChildNodesToBackend({
        childNodes,
        allowLinks,
        shouldReplaceDollarSign,
    });

    return result;
};

export const allContactDynamicDataTypes: ContactDynamicDataType[] = [
    "contact-first-name",
    "contact-last-name",
    "contact-full-name",
];

const validDynamicDataVariables = new Set<DynamicDataType>([
    "event-name",
    "event-start-time",
    "event-start-date",
    "event-link",
    "event-location-address",
    "event-location-name",
    "event-lowest-ticket-price",
    "event-lineup",
    // "event-headliner",
    "event-door-open-time",
    "event-description",
    "contact-first-name",
    "contact-last-name",
    "contact-full-name",
    "write-with-ai-single-event",
    "write-with-ai-upcoming-events",
]);

export const getDynamicDataLabel = (variable: DynamicDataType): string => {
    switch (variable) {
        case "event-name":
            return "Event name";
        case "event-start-date":
            return "Event start date";
        case "event-start-time":
            return "Event start time";
        case "event-link":
            return "Event link";
        case "event-location-address":
            return "Event location address";
        case "event-location-name":
            return "Event location name";
        case "event-lowest-ticket-price":
            return "Lowest ticket price";
        case "event-lineup":
            return "Event lineup";
        // case "event-headliner":
        //     return "Event headliner";
        case "event-door-open-time":
            return "Event door open time";
        case "event-description":
            return "Event description";
        case "contact-first-name":
            return "Contact first name";
        case "contact-last-name":
            return "Contact last name";
        case "contact-full-name":
            return "Contact full name";
        case "write-with-ai-single-event":
            return "Write with AI";
        case "write-with-ai-upcoming-events":
            return "Write with AI";
    }
};

export const getDynamicDataExampleText = ({
    variable,
    settings,
}: {
    variable: NonWriteWithAiDynamicDataType;
    settings: DynamicDataSetting;
}): string => {
    switch (variable) {
        case "event-name":
            return maybeCapitalize("U2 at Redrocks", settings.event_name_all_caps);
        case "event-start-date":
            return maybeCapitalize(settings.event_date_format, settings.event_date_all_caps);
        case "event-start-time":
            return settings.event_time_format;
        case "event-link":
            return "https://example.com/event";
        case "event-location-address":
            return "123 Main St, City, State, Zip";
        case "event-location-name":
            return "Redrocks Amphitheater";
        case "event-lowest-ticket-price":
            return "$50.00";
        case "event-lineup":
            return "U2, The Lumineers, Imagine Dragons";
        // case "event-headliner":
        //     return "U2";
        case "event-door-open-time":
            return settings.event_door_open_time_format;
        case "event-description":
            return maybeCapitalize("Join us for an unforgettable night of music!", settings.event_description_all_caps);
        case "contact-first-name":
            return maybeCapitalize("John", settings.contact_first_name_all_caps);
        case "contact-last-name":
            return maybeCapitalize("Doe", settings.contact_last_name_all_caps);
        case "contact-full-name":
            return maybeCapitalize("John Doe", settings.contact_full_name_all_caps);
    }
};

export const getDynamicDataIcon = (variable: NonWriteWithAiDynamicDataType): Icon => {
    switch (variable) {
        case "event-name":
        case "event-start-date":
        case "event-start-time":
        case "event-link":
        case "event-location-address":
        case "event-location-name":
        case "event-lowest-ticket-price":
        case "event-lineup":
        // case "event-headliner":
        case "event-door-open-time":
        case "event-description":
            return CalendarIcon;
        case "contact-first-name":
        case "contact-last-name":
        case "contact-full-name":
            return PersonIcon;
    }
};

export const getDynamicDataPreviewText = (variable: DynamicDataType, settings: DynamicDataSetting): string => {
    switch (variable) {
        case "event-name":
            return maybeCapitalize("[Event name]", settings.event_name_all_caps);
        case "event-start-date":
            return "[Event start date]";
        case "event-start-time":
            return "[Event start time]";
        case "event-link":
            return "[Event link]";
        case "event-location-address":
            return "[Event location address]";
        case "event-location-name":
            return "[Event location name]";
        case "event-lowest-ticket-price":
            return "[Lowest ticket price]";
        case "event-lineup":
            return "[Event lineup]";
        // case "event-headliner":
        //     return "[Event headliner]";
        case "event-door-open-time":
            return "[Event door open time]";
        case "event-description":
            return maybeCapitalize("[Event description]", settings.event_description_all_caps);
        case "contact-first-name":
            return maybeCapitalize("[First name]", settings.contact_first_name_all_caps);
        case "contact-last-name":
            return maybeCapitalize("[Last name]", settings.contact_last_name_all_caps);
        case "contact-full-name":
            return maybeCapitalize("[Full name]", settings.contact_full_name_all_caps);
        case "write-with-ai-single-event":
            return "[Write with AI]";
        case "write-with-ai-upcoming-events":
            return "[Write with AI]";
    }
};

export const getDynamicDataLengthEstimation = (variable: DynamicDataType) => {
    switch (variable) {
        case "event-name":
            return 20;
        case "event-start-date":
            return 20;
        case "event-start-time":
            return 8;
        case "event-link":
            return 20;
        case "event-location-address":
            return 30;
        case "event-location-name":
            return 16;
        case "event-lowest-ticket-price":
            return 8;
        case "event-lineup":
            return 40;
        case "event-door-open-time":
            return 8;
        case "event-description":
        case "write-with-ai-single-event":
        case "write-with-ai-upcoming-events":
            return 161;
        case "contact-first-name":
        case "contact-last-name":
            return 8;
        case "contact-full-name":
            return 16;
    }
};

const maybeCapitalize = (text: string, useCaps = false) => (useCaps ? text.toLocaleUpperCase() : text);

const temporarilyRemoveSpanTags = (inputString: string) => {
    const spanUuidDict: { [uuid: string]: string } = {};

    const spanPattern = /(<span[^>]*>)([\S\s]*?)(<\/span>)/gi;

    const replaceSpanTags = (_: string, startTag: string, content: string, endTag: string): string => {
        if (content === "") {
            return "";
        }
        const startUuid = v4();
        const endUuid = v4();
        spanUuidDict[startUuid] = startTag;
        spanUuidDict[endUuid] = endTag;

        // should never have newline chars inside style spans
        return `${startUuid}${content.replaceAll("\n", "")}${endUuid}`;
    };

    const modifiedString = inputString.replace(spanPattern, replaceSpanTags);

    return { modifiedString, spanUuidDict };
};

const addBackSpanTags = (inputString: string, spanUuidDict: { [uuid: string]: string }) => {
    let modifiedString = inputString;
    for (const uuidKey in spanUuidDict) {
        modifiedString = modifiedString.replace(uuidKey, spanUuidDict[uuidKey]);
    }

    return modifiedString;
};

export const isEmptyTextNode = (childNode: ChildNode | null) => {
    // if (!!childNode && childNode.nodeType === Node.TEXT_NODE && childNode.textContent === "") {
    //     throw new Error("found empty child node!!!");
    // }
    return !childNode ? false : childNode.nodeType === Node.TEXT_NODE && childNode.textContent === "";
};

export const nodeIsSpecialData = (childNode: ChildNode | null) =>
    !childNode
        ? false
        : childNode.nodeName === "SPAN" &&
          ((childNode as HTMLSpanElement).className === DYNAMIC_DATA_SPAN_CLASS_NAME ||
              (childNode as HTMLSpanElement).className === LINK_SPAN_CLASS_NAME);

export const nodeIsStyledSpan = (childNode: ChildNode | null) =>
    !childNode
        ? false
        : childNode.nodeName === "SPAN" && (childNode as HTMLSpanElement).className === STYLED_TEXT_SPAN_CLASS_NAME;

export const nodeIsEmpty = (childNode: ChildNode) =>
    childNode.childNodes.length === 0 || Array.from(childNode.childNodes).every((node) => isEmptyTextNode(node));

const alterStyleDeclarationInner = ({
    node,
    styleDeclaration,
    shouldRemove = true,
    processFinalNode,
}: {
    node: ChildNode | HTMLElement;
    styleDeclaration: LegoInlineStyle;
    shouldRemove?: boolean;
    processFinalNode: (node: Node) => void;
}) => {
    // text node or one of our special nodes (wrap in a style span)
    if (
        node.nodeType === Node.TEXT_NODE ||
        (node.nodeName === "SPAN" &&
            ((node as HTMLSpanElement).className === DYNAMIC_DATA_SPAN_CLASS_NAME ||
                (node as HTMLSpanElement).className === LINK_SPAN_CLASS_NAME))
    ) {
        if (isEmptyTextNode(node)) {
            node.remove();
            return;
        }

        const newStyleSpan = document.createElement("span");

        if (
            node.nodeName === "SPAN" &&
            ((node as HTMLSpanElement).className === DYNAMIC_DATA_SPAN_CLASS_NAME ||
                (node as HTMLSpanElement).className === LINK_SPAN_CLASS_NAME)
        ) {
            newStyleSpan.contentEditable = "false";
        }

        newStyleSpan.className = STYLED_TEXT_SPAN_CLASS_NAME;
        for (const styleAttribute in styleDeclaration) {
            const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
            const value = styleDeclaration[typedStyleAttribute];
            if (value === "") {
                processFinalNode(node);
                return;
            }
            if (value !== undefined) {
                (newStyleSpan as HTMLSpanElement).style[typedStyleAttribute] = value;
            }
        }
        newStyleSpan.appendChild(node);
        processFinalNode(newStyleSpan);
        return newStyleSpan;
    }
    // style span case
    else if (node.nodeName === "SPAN" && (node as HTMLSpanElement).className === STYLED_TEXT_SPAN_CLASS_NAME) {
        for (const styleAttribute in styleDeclaration) {
            const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
            const value = styleDeclaration[typedStyleAttribute];
            if (value !== undefined) {
                (node as HTMLSpanElement).style[typedStyleAttribute] = value;
            }
        }
        if ((node as HTMLSpanElement).style.cssText === "") {
            if (shouldRemove) {
                node.childNodes.forEach((node) => processFinalNode(node));
                node.remove();
            } else {
                const firstChild = node.firstChild as ChildNode;
                node.replaceWith(firstChild);
                return firstChild;
            }
        } else {
            processFinalNode(node);
        }
        return node as HTMLSpanElement;
    } else {
        processFinalNode(node);
    }
};

export const setRangeToRange = (range: Range) => {
    const selection = window.getSelection();

    // Create a new range or else for some reason we lose the visual highlighting
    const newRange = range.cloneRange();
    if (!!selection) {
        selection.removeAllRanges();
        selection.addRange(newRange);
    }
};

const createSiblingStyleSpansWhenSelectionInsideOneStyleSpan = ({
    range,
    styleDeclaration,
    customChildElement,
    shouldCloneFinalRange = true,
}: {
    range: Range;
    styleDeclaration: LegoInlineStyle;
    customChildElement?: HTMLElement;
    shouldCloneFinalRange?: boolean;
}) => {
    const selection = window.getSelection();

    const text = range.extractContents();
    const styleSpanContainer = range.startContainer.parentElement as HTMLSpanElement;
    const newLineDiv = styleSpanContainer.parentElement as HTMLDivElement;
    const newStyleSpan = styleSpanContainer.cloneNode() as HTMLSpanElement;
    newStyleSpan.append(customChildElement ?? text);
    if (!!customChildElement) {
        newStyleSpan.contentEditable = "false";
    }
    for (const styleAttribute in styleDeclaration) {
        const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
        newStyleSpan.style[typedStyleAttribute] = styleDeclaration[typedStyleAttribute] as string;
    }
    if ((newStyleSpan as HTMLSpanElement).style.cssText === "") {
        newStyleSpan.replaceWith(...newStyleSpan.childNodes);
    }

    range.insertNode(newStyleSpan);

    const firstHalfText = styleSpanContainer.firstChild?.textContent ?? "";
    const secondHalfText = styleSpanContainer.lastChild?.textContent ?? "";

    // Create new spans
    const firstHalfSpan = document.createElement("span");
    firstHalfSpan.textContent = firstHalfText;
    firstHalfSpan.style.cssText = styleSpanContainer.style.cssText; // Copy styles
    firstHalfSpan.className = STYLED_TEXT_SPAN_CLASS_NAME;

    const secondHalfSpan = document.createElement("span");
    secondHalfSpan.textContent = secondHalfText;
    secondHalfSpan.style.cssText = styleSpanContainer.style.cssText; // Copy styles
    secondHalfSpan.className = STYLED_TEXT_SPAN_CLASS_NAME;

    // Replace the old outer span with the new spans
    newLineDiv.insertBefore(firstHalfSpan, styleSpanContainer);
    newLineDiv.insertBefore(newStyleSpan, styleSpanContainer);
    newLineDiv.insertBefore(secondHalfSpan, styleSpanContainer);
    newLineDiv.removeChild(styleSpanContainer);

    range.setStartBefore(newStyleSpan);
    range.setEndAfter(newStyleSpan);

    if (shouldCloneFinalRange) {
        const newRange = range.cloneRange();
        if (!!selection) {
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
    }
};

// creat a node list and then insert those
export const alterStyleDeclaration = ({
    range,
    styleDeclaration,
    shouldCloneFinalRange = true,
}: {
    range: Range;
    styleDeclaration: LegoInlineStyle;
    shouldCloneFinalRange?: boolean;
}) => {
    const selection = window.getSelection();

    // this ensures we don't insert style spans as children of other style spans
    if (isSelectionSingleTextNodeWithStyleSpanParent(range) && range.startContainer.parentElement) {
        createSiblingStyleSpansWhenSelectionInsideOneStyleSpan({ range, styleDeclaration, shouldCloneFinalRange });
        return;
    }

    // only selected special node, but selection is showing text node
    if (
        (range.endContainer.parentElement?.className === LINK_SPAN_CLASS_NAME ||
            range.endContainer.parentElement?.className === DYNAMIC_DATA_SPAN_CLASS_NAME) &&
        range.endContainer.parentElement === range.startContainer.parentElement
    ) {
        // if it already has a parent style node, process that
        const finalNode =
            range.endContainer.parentElement.parentElement?.className === STYLED_TEXT_SPAN_CLASS_NAME
                ? range.endContainer.parentElement.parentElement
                : range.endContainer.parentElement;
        const processFinalNode = (_: Node) => null;
        const styleSpan = alterStyleDeclarationInner({
            node: finalNode,
            styleDeclaration,
            shouldRemove: false,
            processFinalNode,
        });

        if (!!styleSpan) {
            range.setStartBefore(styleSpan);
            range.setEndAfter(styleSpan);

            if (shouldCloneFinalRange) {
                const newRange = range.cloneRange();
                if (!!selection) {
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                }
            }
        }
        return;
    }

    const isMultiLineSelection = isSelectionMultiline(range);

    const partialStartContainer =
        isMultiLineSelection && range.startContainer.nodeType === Node.TEXT_NODE && range.startOffset !== 0
            ? range.startContainer.parentElement?.closest(`.${NEW_LINE_CLASS_NAME}`)
            : range.startContainer.nodeType === Node.ELEMENT_NODE &&
                (range.startContainer as HTMLElement).className === NEW_LINE_CLASS_NAME
              ? (range.startContainer as HTMLDivElement)
              : null;

    const partialEndContainer =
        isMultiLineSelection &&
        range.endContainer.nodeType === Node.TEXT_NODE &&
        (range.endContainer as Text).length !== range.endOffset
            ? range.endContainer.parentElement?.closest(`.${NEW_LINE_CLASS_NAME}`)
            : range.endContainer.nodeType === Node.ELEMENT_NODE &&
                (range.endContainer as HTMLElement).className === NEW_LINE_CLASS_NAME
              ? (range.endContainer as HTMLDivElement)
              : null;

    const contents = range.extractContents().childNodes;

    const tempNewContainer = document.createElement("div");

    // clone range contents with added style (deep loop) into a temporary container
    let node = contents[0];
    while (!!node) {
        if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).className === NEW_LINE_CLASS_NAME) {
            const newLineDivCopy = document.createElement("div");
            newLineDivCopy.className = NEW_LINE_CLASS_NAME;
            let childNode = (node as HTMLDivElement).childNodes[0];
            const processFinalNode = (n: Node) => newLineDivCopy.appendChild(n);
            while (!!childNode) {
                alterStyleDeclarationInner({ node: childNode, styleDeclaration, processFinalNode });
                childNode = (node as HTMLDivElement).childNodes[0];
            }
            node.remove();
            tempNewContainer.appendChild(newLineDivCopy);
        } else {
            const processFinalNode = (n: Node) => tempNewContainer.appendChild(n);
            alterStyleDeclarationInner({ node, styleDeclaration, processFinalNode });
        }
        node = contents[0];
    }

    const finalContents = tempNewContainer.childNodes;

    let firstNode: ChildNode | undefined;
    let lastNode: ChildNode | undefined;

    // iterate backwards (has to be for range method) and insert into the range
    let finalNode = finalContents[finalContents.length - 1];
    let isLast = true;
    while (!!finalNode) {
        if (isLast) {
            isLast = false;
            if (
                !!partialEndContainer &&
                finalNode.hasChildNodes() &&
                (finalNode as HTMLElement).className === NEW_LINE_CLASS_NAME
            ) {
                lastNode = finalNode.childNodes[finalNode.childNodes.length - 1];
                partialEndContainer.prepend(...finalNode.childNodes);
                finalNode.remove();
                finalNode = finalContents[finalContents.length - 1];
                continue;
            } else {
                lastNode = finalNode;
            }
        }
        if (finalContents.length === 1) {
            if (
                !!partialStartContainer &&
                finalNode.hasChildNodes() &&
                (finalNode as HTMLElement).className === NEW_LINE_CLASS_NAME
            ) {
                firstNode = finalNode.childNodes[0];
                partialStartContainer.append(...finalNode.childNodes);
                finalNode.remove();
                finalNode = finalContents[finalContents.length - 1];
                continue;
            } else {
                firstNode = finalNode;
            }
        }
        range.insertNode(finalNode);

        finalNode = finalContents[finalContents.length - 1];
    }

    if (!!firstNode) {
        // if empty newline nodes created, remove them so the range doesn't get messed up
        if (
            firstNode.nodeType === Node.ELEMENT_NODE &&
            (firstNode as HTMLElement).previousElementSibling?.className === NEW_LINE_CLASS_NAME &&
            (firstNode as HTMLElement).previousElementSibling?.innerHTML === ""
        ) {
            (firstNode as HTMLElement).previousElementSibling?.remove();
        }
        range.setStartBefore(firstNode);
    }

    if (!!lastNode) {
        if (
            lastNode.nodeType === Node.ELEMENT_NODE &&
            (lastNode as HTMLElement).className === NEW_LINE_CLASS_NAME &&
            (lastNode as HTMLElement).innerHTML === ""
        ) {
            lastNode.remove();
            if (firstNode) {
                range.setEndAfter(firstNode);
            }
        } else {
            // if empty newline nodes created, remove them so the range doesn't get messed up
            if (
                lastNode.nodeType === Node.ELEMENT_NODE &&
                (lastNode as HTMLElement).nextElementSibling?.className === NEW_LINE_CLASS_NAME &&
                (lastNode as HTMLElement).nextElementSibling?.innerHTML === ""
            ) {
                (lastNode as HTMLElement).nextElementSibling?.remove();
            }
            range.setEndAfter(lastNode);
        }
    }

    if (shouldCloneFinalRange) {
        // Create a new range or else for some reason we lose the visual highlighting
        const newRange = range.cloneRange();
        if (!!selection) {
            selection.removeAllRanges();
            selection.addRange(newRange);
        }
    }
};

const isSelectionMultiline = (range: Range) => {
    let start =
        range.startContainer.nodeType === Node.ELEMENT_NODE
            ? (range.startContainer as HTMLElement)
            : range.startContainer.parentElement;

    if (
        range.startContainer.nodeType === Node.ELEMENT_NODE &&
        (range.startContainer as HTMLElement).className.includes("content-editable")
    ) {
        start = range.startContainer.childNodes[range.startOffset] as HTMLElement;
    }

    let end =
        range.endContainer.nodeType === Node.ELEMENT_NODE
            ? (range.endContainer as HTMLElement)
            : range.endContainer.parentElement;

    if (
        range.endContainer.nodeType === Node.ELEMENT_NODE &&
        (range.endContainer as HTMLElement).className.includes("content-editable")
    ) {
        end = range.endContainer.childNodes[range.endOffset] as HTMLElement;
    }

    return start?.closest(`.${NEW_LINE_CLASS_NAME}`) !== end?.closest(`.${NEW_LINE_CLASS_NAME}`);
};

const isSelectionSingleTextNodeWithStyleSpanParent = (range: Range) =>
    range.startContainer.parentElement === range.endContainer.parentElement &&
    range.startContainer.parentElement?.className === STYLED_TEXT_SPAN_CLASS_NAME;

export const updateOrRemoveRedundantAndEmptyStyleSpans = ({
    rootElement,
    styleDeclaration,
}: {
    rootElement: Element;
    styleDeclaration: LegoInlineStyle;
}) => {
    const allStyleSpans = rootElement.querySelectorAll(
        `.${STYLED_TEXT_SPAN_CLASS_NAME}`,
    ) as NodeListOf<HTMLSpanElement>;

    allStyleSpans.forEach((styleSpan) => {
        for (const styleAttribute in styleDeclaration) {
            const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
            if (styleSpan.style[typedStyleAttribute] === String(styleDeclaration[typedStyleAttribute])) {
                styleSpan.style[typedStyleAttribute] = "";
            }

            if (
                typedStyleAttribute === "color" &&
                rgbToHex(styleSpan.style[typedStyleAttribute]) === String(styleDeclaration[typedStyleAttribute])
            ) {
                styleSpan.style[typedStyleAttribute] = "";
            }
        }
        if (styleSpan.innerHTML === "") {
            styleSpan.remove();
        } else if (styleSpan.style.cssText === "") {
            styleSpan.replaceWith(...styleSpan.childNodes);
        }
    });
};

const doAllSelectedHaveStyleAttributeInner = ({
    nodes,
    styleDeclaration,
}: {
    nodes: NodeListOf<ChildNode>;
    styleDeclaration: LegoInlineStyle;
}) => {
    return [...nodes].every((node) => {
        if (isEmptyTextNode(node)) {
            return true;
        }
        if (node.nodeType !== Node.ELEMENT_NODE || (node as HTMLElement).className !== STYLED_TEXT_SPAN_CLASS_NAME) {
            return false;
        }
        for (const styleAttribute in styleDeclaration) {
            const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
            if ((node as HTMLSpanElement).style[typedStyleAttribute] !== styleDeclaration[typedStyleAttribute]) {
                return false;
            }
        }
        return true;
    });
};

const doAllSelectedHaveStyleAttribute = ({
    range,
    styleDeclaration,
}: {
    range: Range;
    styleDeclaration: LegoInlineStyle;
}) => {
    if (isSelectionSingleTextNodeWithStyleSpanParent(range) && range.startContainer.parentElement) {
        for (const styleAttribute in styleDeclaration) {
            const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
            if (
                range.startContainer.parentElement.style[typedStyleAttribute] !== styleDeclaration[typedStyleAttribute]
            ) {
                return false;
            }
        }
        return true;
    }

    if (
        (range.endContainer.parentElement?.className === LINK_SPAN_CLASS_NAME ||
            range.endContainer.parentElement?.className === DYNAMIC_DATA_SPAN_CLASS_NAME) &&
        range.endContainer.parentElement === range.startContainer.parentElement
    ) {
        if (range.endContainer.parentElement.parentElement?.className === STYLED_TEXT_SPAN_CLASS_NAME) {
            for (const styleAttribute in styleDeclaration) {
                const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
                if (
                    range.endContainer.parentElement.parentElement.style[typedStyleAttribute] !==
                    styleDeclaration[typedStyleAttribute]
                ) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    const isMultiLineSelection = isSelectionMultiline(range);

    const contents = range.cloneContents().childNodes;

    if (isMultiLineSelection) {
        for (const newLineDiv of contents) {
            const allChildNodesHaveStyle = doAllSelectedHaveStyleAttributeInner({
                nodes: newLineDiv.childNodes,
                styleDeclaration,
            });
            if (
                !allChildNodesHaveStyle &&
                !(newLineDiv.childNodes.length === 1 && newLineDiv.childNodes[0].nodeName === "BR")
            ) {
                return false;
            }
        }
        return true;
    } else {
        return doAllSelectedHaveStyleAttributeInner({
            nodes: contents,
            styleDeclaration,
        });
    }
};

type SelectionStyleProperty = "selection-style-mixed" | "selection-style-all-not-target" | "selection-style-all-target";

// only for bold, italic
export const makeBoldItalicProperties = ({
    containerHasTargetStyle,
    range,
    styleDeclarationTarget,
    styleDeclarationNonTarget,
    removeStyle,
    addTargetStyle,
    addNonTargetStyle,
}: {
    containerHasTargetStyle: boolean;
    range: Range;
    styleDeclarationTarget: LegoInlineStyle;
    styleDeclarationNonTarget: LegoInlineStyle;
    removeStyle: () => void;
    addTargetStyle: () => void;
    addNonTargetStyle: () => void;
}) => {
    let selectionStyleProperty: SelectionStyleProperty = "selection-style-mixed";

    // container is bold or italic
    if (containerHasTargetStyle) {
        if (doAllSelectedHaveStyleAttribute({ range, styleDeclaration: styleDeclarationNonTarget })) {
            selectionStyleProperty = "selection-style-all-not-target";
        } else {
            const contents = range.cloneContents();
            const styleSpans = contents.querySelectorAll(
                `.${STYLED_TEXT_SPAN_CLASS_NAME}`,
            ) as NodeListOf<HTMLSpanElement>;
            if (
                ![...styleSpans].some((span) => {
                    for (const styleAttribute in styleDeclarationNonTarget) {
                        const typedStyleAttribute = styleAttribute as keyof LegoInlineStyle;
                        if (
                            span.style[typedStyleAttribute] ===
                            (styleDeclarationNonTarget[typedStyleAttribute] as string)
                        ) {
                            return true;
                        }
                    }
                    return false;
                })
            ) {
                selectionStyleProperty = "selection-style-all-target";
            }
        }
        switch (selectionStyleProperty) {
            case "selection-style-mixed":
                return {
                    showIsTarget: false,
                    onClick: removeStyle,
                };
            case "selection-style-all-not-target":
                return {
                    showIsTarget: false,
                    onClick: removeStyle,
                };
            case "selection-style-all-target":
                return {
                    showIsTarget: true,
                    onClick: addNonTargetStyle,
                };
        }
    }

    // container is not bold or italic
    else {
        if (doAllSelectedHaveStyleAttribute({ range, styleDeclaration: styleDeclarationTarget })) {
            selectionStyleProperty = "selection-style-all-target";
        }
        switch (selectionStyleProperty) {
            case "selection-style-all-target":
                return {
                    showIsTarget: true,
                    onClick: removeStyle,
                };
            // this one covered too, same result as "selection-style-mixed"
            // case "selection-style-all-not-target":
            case "selection-style-mixed":
                return {
                    showIsTarget: false,
                    onClick: addTargetStyle,
                };
        }
    }
};

// these are simpler, don't need to think about container styles
export const makeUnderlineStrikethroughProperties = ({
    range,
    styleDeclarationTarget,
    removeStyle,
    addTargetStyle,
}: {
    range: Range;
    styleDeclarationTarget: LegoInlineStyle;
    removeStyle: () => void;
    addTargetStyle: () => void;
}) => {
    let selectionStyleProperty: SelectionStyleProperty = "selection-style-mixed";

    if (doAllSelectedHaveStyleAttribute({ range, styleDeclaration: styleDeclarationTarget })) {
        selectionStyleProperty = "selection-style-all-target";
    }

    switch (selectionStyleProperty) {
        case "selection-style-mixed":
            return {
                showIsTarget: false,
                onClick: addTargetStyle,
            };
        case "selection-style-all-target":
            return {
                showIsTarget: true,
                onClick: removeStyle,
            };
    }
};

export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : "";
};

export const rgbToHex = (rgbString: string) => {
    const match = rgbString.match(/\d+/g);
    if (!match) {
        return rgbString;
    }
    const [r, g, b] = match.map(Number);
    if (!r || !g || !b) {
        return rgbString;
    }
    return `#${[r, g, b]
        .map((x) => x.toString(16).padStart(2, "0"))
        .join("")
        .toUpperCase()}`;
};

export const getColorCurrentlySelected = ({ range, containerColor }: { range: Range; containerColor?: string }) => {
    if (!containerColor) {
        return null;
    }

    const contents = range.cloneContents();

    const getColorCurrentlySelectedInner = (childNodes: ChildNode[], callingRecursively: boolean) => {
        // no nodes are style spans with color
        const noTopLevelNodesAreStyledWithColor = childNodes.every(
            (node) =>
                node.nodeType === Node.TEXT_NODE ||
                node.nodeName === "BR" ||
                (node.nodeName === "SPAN" && (node as HTMLSpanElement).style.color === ""),
        );
        if (noTopLevelNodesAreStyledWithColor) {
            // only check this condition if we are dealing with only the cloned range contents
            if (!callingRecursively) {
                const parentIsSpanStyledWithColor =
                    (range.startContainer.parentElement as HTMLElement).style.color !== "";
                // selected text within a styled span
                if (parentIsSpanStyledWithColor) {
                    const color = (range.startContainer.parentElement as HTMLElement).style.color;
                    if (!!color) {
                        return rgbToHex(color);
                    }
                }
            }
            return containerColor;
        }

        let firstColor = "";
        // or every top level node is a style span with same color
        const allTopLevelNodesAreStyledWithSameColor = childNodes.every((node) => {
            if (node.nodeName !== "SPAN") {
                return false;
            }
            if ((node as HTMLSpanElement).style.color !== "" && firstColor === "") {
                firstColor = (node as HTMLSpanElement).style.color;
            }
            return (node as HTMLSpanElement).style.color === firstColor;
        });
        if (allTopLevelNodesAreStyledWithSameColor && !!firstColor) {
            return rgbToHex(firstColor);
        }

        // mixed color selection
        return null;
    };

    // selected multiple lines
    if (
        contents.childNodes[0]?.nodeName === "DIV" &&
        (contents.childNodes[0] as HTMLDivElement).className === NEW_LINE_CLASS_NAME
    ) {
        const firstLineColor = getColorCurrentlySelectedInner([...contents.childNodes[0].childNodes], true);

        const everyLineIsSameColor = [...contents.childNodes].every(
            (div) => getColorCurrentlySelectedInner([...div.childNodes], true) === firstLineColor,
        );

        if (everyLineIsSameColor && !!firstLineColor) {
            return rgbToHex(firstLineColor);
        }

        // mixed color selection
        return null;
    }

    return getColorCurrentlySelectedInner([...contents.childNodes], false);
};

export const removeAllEmptyTextNodesAndStripDivStyle = (node: Node) => {
    for (let i = 0; i < node.childNodes.length; i++) {
        const child = node.childNodes[i];
        if (child.nodeValue !== null && child.nodeType === Node.TEXT_NODE && child.nodeValue === "") {
            node.removeChild(child);
            i--;
        } else if (child.nodeType === 1) {
            if (
                (child as HTMLElement).className === NEW_LINE_CLASS_NAME &&
                (child as HTMLElement).style.cssText !== ""
            ) {
                (child as HTMLElement).style.cssText = "";
            }
            removeAllEmptyTextNodesAndStripDivStyle(child);
        }
    }
};
